import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { ERHSelect } from '../../Select.jsx';
import { ERHRangeSlider } from '../../RangeSlider';
import { ERHRadioBtn } from '../../RadioButton';
import { ERHButton } from '../../Button';
import { formatAmount, optionsTimeInBusiness } from '../../../helpers';
import { ERHInput } from '../../Input';
import { API } from 'aws-amplify';
import { Error } from './Error';
import { handleApplicationNotification, handleGrantTextNotification } from '../../../services.js';
import { getIneligibilityPage } from '../../../helpers/pages.js';
import { fundingOptions } from './constants.js';
 

export function FormCheckQualification({
  values,
  setValues,
  backStep,
  nextStep,
  validations,
  currentStep,
  handleSelectInputChange,
  currentValueSelect,
  setIsLoading,
  error,
  setError,
  touched,
  completedToryBurchApplication,
  ...rest
}) {
  const { business_based_in_the_us: basedInTheUS, typeOfFunding } = values;
  let grantSelected = typeOfFunding === fundingOptions.grant;
  let toryBurchSelected = typeOfFunding === fundingOptions.toryBurch;
  
  const {
    loan_amount_requested,
    credit_score,
    us_citizen,
    first_name,
    last_name,
    email,
    time_in_business,
    business_based_in_the_us,
    two_or_more_employees,
  } = validations;

  const saveAndExitRequiredFields = email && first_name && last_name;

  const isCompleteStep = () => {
    if (!rest.disabled) {
      let isEveryTrue = business_based_in_the_us && time_in_business && first_name && last_name && email && typeOfFunding;
      if (isEveryTrue && !grantSelected) {
        isEveryTrue = credit_score && loan_amount_requested && two_or_more_employees;
      }
      return !!isEveryTrue;
    }
    return false;
  };

  const stepComplete = React.useMemo(isCompleteStep, [
    first_name,
    last_name,
    loan_amount_requested,
    credit_score,
    us_citizen,
    business_based_in_the_us,
    two_or_more_employees,
    email,
    time_in_business,
    typeOfFunding,
    rest.disabled
  ]);

  useEffect(() => {
    setValues({
      ...values,
      typeOfFunding,
      grantSelected,
      toryBurchSelected
    })
    rest.setValidations({
      ...validations,
      typeOfFunding: true
    })
  }, [typeOfFunding]);

  const allCheckboxSelected = grantSelected ?
    !!values.us_citizen && !!values.business_based_in_the_us && !!typeOfFunding
    :
    !!values.us_citizen && !!values.business_based_in_the_us && !!values.two_or_more_employees && !!typeOfFunding;

  const handleSubmit = async (event, isSaveAndContinue) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      let step;
      if (isSaveAndContinue) {
        values.eligible = allCheckboxSelected;
        step = currentStep + 1;
      } else {
        step = currentStep;
      }
      const payload = { ...values, step, page: 'eligibilityData' };
      await API.post('enrichHer-mvp', 'saveApplicationFormData', { body: payload });
      
      const queryParams = { email: values.email, timestamp: new Date().getTime() };
      const userResponse = await API.get('wordpress', 'wp-json/user_manager/v1/user', { queryStringParameters: queryParams });
      const hasAccount = !!userResponse?.user?.ID;
      if(isSaveAndContinue) {
        if (!allCheckboxSelected) {
          const ineligibilityPage = getIneligibilityPage(basedInTheUS, typeOfFunding);
          window.location.replace(ineligibilityPage);
        } else {
          hasAccount && await Promise.allSettled([
            handleApplicationNotification('NOTIFICATE', 'RESUME_YOUR_APPLICATION', values.email),
            handleGrantTextNotification({ type: 'RESUME_YOUR_APPLICATION', email: values.email }),
          ]);
          nextStep();
        }
      } else {
        hasAccount && await Promise.allSettled([
          handleApplicationNotification('NOTIFICATE', 'RESUME_YOUR_APPLICATION', values.email),
          handleGrantTextNotification({ type: 'RESUME_YOUR_APPLICATION', email: values.email }),
        ]);
        rest.setModalShowing(hasAccount ? 'Registered User' : 'Account Overlay');
      } 
    } catch {
      setError('Sorry there was an error, please try again');
    } finally {
      setIsLoading(false);
    }
  }


  return (
    <>
      <div className={`fragment-form-application ${rest.disabled && 'inactive'}`}>
        <h3 className='title-form my-3'> You are in the right place </h3>
        <div className={'introductory-div'}>
          <p>You are among the ranks of women and founders of color leading companies that wield increased local and national economic power.<br /><br />
             EnrichHER is committed to funding the future of business and fast-tracking new majority founders and company leaders with affordable and accessible working capital.<br /><br />
             By submitting this application, you are taking a big step forward, and you should be very proud. We can’t wait to learn more about your company.
          </p>
        </div>
        <h3 className='title-form my-3'> Check to see if you qualify </h3>
        <div className='form'>
          <Row>
            <Col xs={12} sm={6}>
              <ERHInput name='first_name' value={values.first_name} type='text' id='first-name' label='First name*'
                placeholder='Your first name' classContainer={'my-2'}
                {...rest} />
            </Col>
            <Col xs={12} sm={6}>
              <ERHInput name='last_name' value={values.last_name} type='text' id='last-name' label='Last name*'
                placeholder='Your last name' classContainer={'my-2'} {...rest} />
            </Col>
            <Col xs={12}>
              <ERHInput type='email' id='email' name='email' value={values.email} label='Email address*'
                placeholder='Email address'
                required={true}
                classContainer={'my-2'} error={touched.email && !validations.email && 'Please enter a valid email address'} {...rest} />
            </Col>
            <Col xs={12} >
              <p className='label-input mb-0 mt-2'>What type of funding are you seeking? *</p>
              <div className='d-flex flex-wrap' onChange={(event) => setValues({ ...values, typeOfFunding: event.target.name })}>
                <div className='funding-container'>
                  {/* {completedToryBurchApplication &&
                    <div className={`col-radio-btn`}>
                      <ERHRadioBtn
                        classContainer='mr-4 my-2 funding-btn'
                        label='Tory Burch Loan Program'
                        name='Tory Burch Loan Program'
                        id='toryBurch'
                        checked={typeOfFunding === fundingOptions.toryBurch}
                        value={true}
                      />
                    </div>
                  } */}
                  <div className={`col-radio-btn`}>
                    <ERHRadioBtn
                      classContainer='mr-4 my-2 funding-btn'
                      label='I want an EnrichHER Grant'
                      name='Grant'
                      id='grant'
                      checked={typeOfFunding === fundingOptions.grant}
                      value={true}
                    />
                  </div>
                  {/* {!completedToryBurchApplication && */}
                    <div className={`col-radio-btn`}>
                      <ERHRadioBtn
                        classContainer='mr-4 my-2 funding-btn'
                        label='I want an EnrichHER Loan'
                        name='Loan'
                        id='loan'
                        checked={typeOfFunding === fundingOptions.loan}
                        value={true}
                      />
                    </div>
                  {/* } */}
                  <div className='col-radio-btn'>
                    <ERHRadioBtn
                      classContainer='mr-4 my-2 funding-btn'
                      label='I want to view all EnrichHER Funding Options'
                      name='All EnrichHER Funding Options'
                      id='allFundingOptions'
                      checked={typeOfFunding === fundingOptions.allFunding}
                      value={true}
                    />
                  </div>
                </div>
              </div>
            </Col>
            {!grantSelected &&
              <>
                <Col xs={12} sm={6}>
                  <ERHRangeSlider value={values.loan_amount_requested} id='loan_amount_requested' name='loan_amount_requested'
                    min={10000} max={250000} step={10000} label='How much funding are you seeking?*' placeholder='Last name'
                    classContainer={'my-2'}
                    valueShow={values.loan_amount_requested ? '$' + formatAmount(values.loan_amount_requested) : 'select value'} 
                    {...rest} 
                    />
                </Col>
                <Col xs={12} sm={6}>
                  <ERHRangeSlider value={values.credit_score} id='what is your credit score' name='credit_score' min='590'
                    max='850' step='10' label='What is your credit score?*' placeholder='Last name'
                    classContainer={'my-2'} valueShow={values.credit_score} {...rest} />
                </Col>
              </>
            }
            <Col xs={12}>
              <ERHSelect placeholder='Select number of years' name='time_in_business' options={optionsTimeInBusiness} id='time_in_business'
                classContainer={'my-2'}
                label='How many years have you been in business?*' value={values.time_in_business} onChange={handleSelectInputChange} />
            </Col>
            <Col xs={12}>
              <p className='label-input mb-0 mt-2'> Are you a US Citizen or Permanent Resident? * </p>
              <div className='d-flex flex-wrap'>
                <div className='col-radio-btn'>
                  <ERHRadioBtn
                    classContainer='mr-4 my-2'
                    label='Yes, I am' name='us_citizen'
                    id='us_citizen_yes'
                    value={true}
                    checked={values.us_citizen !== null && !!values.us_citizen}
                    {...rest}
                  />
                </div>
                <div className='col-radio-btn'>
                  <ERHRadioBtn checked={values.us_citizen !== null && !values.us_citizen} classContainer='mr-4 my-2'
                    label='No, I am not'
                    name='us_citizen'
                    id='us_citizen_no' {...rest} value={false} />
                </div>
              </div>

            </Col>
            <Col xs={12}>
              <p className='label-input mb-0 mt-2'> Is your business based in the US? * </p>
              <div className='d-flex flex-wrap'>
                <div className='col-radio-btn'>
                  <ERHRadioBtn checked={values.business_based_in_the_us !== null && !!values.business_based_in_the_us}
                    classContainer='mr-4 my-2' label='Yes, it is' name='business_based_in_the_us'
                    id='business_based_in_the_us-positive' value={true} {...rest} />
                </div>
                <div className='col-radio-btn'>
                  <ERHRadioBtn checked={values.business_based_in_the_us !== null && !values.business_based_in_the_us}
                    classContainer='mr-4 my-2' label='No, it is not' negative name='business_based_in_the_us'
                    id='business_based_in_the_us-negative' value={false} {...rest} />
                </div>
              </div>
            </Col>
            {!grantSelected &&
              <Col xs={12}>
                <p className='label-input mb-0 mt-2'> Do you have 2+ employees or contractors? * </p>
                <div className='d-flex flex-wrap'>
                  <div className='col-radio-btn'>
                    <ERHRadioBtn checked={values.two_or_more_employees !== null && !!values.two_or_more_employees}
                      classContainer='mr-4 my-2' label='Yes, I do'
                      name='two_or_more_employees'
                      id='two_or_more_employees-positive' value={true} {...rest} />
                  </div>
                  <div className='col-radio-btn'>
                    <ERHRadioBtn checked={values.two_or_more_employees !== null && !values.two_or_more_employees}
                      classContainer='mr-4 my-2' label='No, I don’t' negative name='two_or_more_employees'
                      id='two_or_more_employees-negative' value={false} {...rest} />
                  </div>
                </div>
              </Col>
            }
          </Row>
        </div>
        {error && <Error error={error} />}
        <Row className="my-4">
          <Col xs="12" md="4">
            <ERHButton className='my-2' onClick={e => {
              e.preventDefault();
              window.open(process.env.REACT_APP_BASE_WP_URL + '/dashboard/', '_blank') ||
                window.location.replace(process.env.REACT_APP_BASE_WP_URL + '/dashboard/');

            }} outline target='_blank' href={process.env.REACT_APP_BASE_WP_URL + 'dashboard/'}> cancel</ERHButton>
          </Col>
          <Col xs="12" md="8" className="d-flex justify-content-end flex-wrap">
              <ERHButton 
                className='my-2 mx-1' 
                onClick={(event) => handleSubmit(event, false)} 
                disabled={!saveAndExitRequiredFields}
              > 
                save & exit 
              </ERHButton>
              <ERHButton 
                className='my-2 mx-1' 
                onClick={(event) => handleSubmit(event, true)} 
                disabled={!stepComplete}
              > 
                save & continue 
              </ERHButton>
          </Col>
        </Row>
      </div>
    </>);
}

