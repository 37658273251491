import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { ERHButton } from '../../Button';
import { Error } from './Error';
import DocusignClickWrap from './DocusignClickWrap';

export const FormTermsAgreement = ({
  backStep,
  nextStep,
  currentStep,
  values,
  setValues,
  setIsLoading,
  error,
  setError,
  touched,
  handleSelectInputChange,
  validations,
  handleSubmit,
  ...rest
}) => {

  const [terms_and_conditions, setClickWrapAccepted] = useState(false);

  useEffect(() => {
    setValues({
      ...values,
      terms_and_conditions
    })
    rest.setValidations({
      ...validations,
      terms_and_conditions: true
    })
  }, [terms_and_conditions]);

  const isCompleteStep = () => {
    if (!rest.disabled) {
      return (
        terms_and_conditions
      )
    }
    return false;
  };

  const stepComplete = React.useMemo(isCompleteStep, [
    terms_and_conditions,
    rest.disabled
  ]);

  return (
    <div className={'fragment-form-application'}>
      <h3 className='title-form my-3'> Terms Agreement </h3>
      <div className='form'>
        {values.email && values.typeOfFunding &&
          <Row>
            <Col xs={12}>
              <DocusignClickWrap email={values.email} typeOfFunding={values.typeOfFunding} accepted={setClickWrapAccepted} id='clickwrap' />
            </Col>
          </Row>}
      </div>
      {error && <Error error={error} />}
      <div className='d-flex align-items-center flex-grow-1 justify-content-between my-4 flex-wrap'>
        <ERHButton className='my-2' onClick={backStep} outline> Back </ERHButton>
        <ERHButton 
          className='my-2' 
          onClick={handleSubmit(true, 'termsAndConditions')} 
          disabled={!stepComplete}
        > 
          Continue 
        </ERHButton>
      </div>
    </div>
  );
};
