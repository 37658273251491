export function formatAmount(amount, decimalCount = 2, thousands = ',') {
  const negativeSign = amount < 0 ? '-' : '';
  let i = parseFloat(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
  var res = i.split('.');
  if (res.length == 1 || res[1].length < 3) {
    i = parseFloat(i).toFixed(decimalCount);
  }
  let j = (i.length > 3) ? i.length % 3 : 0;

  return res[0] == '0' ? parseFloat(i).toFixed(decimalCount) : negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);
}

export function formatHeight(value){
  let arr = value.length >= 3 ? value.split('') : value + '.0';
  let inchees = arr.length >= 3 ? arr[2]  + '’’' : '';
  return arr.length >= 3 ? arr.slice(0,1)[0] + '’' + inchees  : arr + '’';
}

export function validatePhoneNumber(phone) {
  const phoneRegex = /^(?:\+?1[-.●]?)?\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
  return phoneRegex.test(phone);
}

export function validateWebsite(websiteurl) {
  const websiteRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
  return websiteRegex.test(websiteurl);
}

export const validateEmail = (email) => {
  const mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return mailFormat.test(email)
}

export const validateChildrenQty = (number) => {
  const numRegex = /^(0|[1-9][0-9]{0,9})$/;
  return numRegex.test(number);
}

export const validateDate = (date) => {
  const dateRegex = /^(((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))[-/]?[0-9]{4}|02[-/]?29[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/;
  const today = new Date();
  const birthdate = new Date(date);
  let age = today.getFullYear() - birthdate.getFullYear();
  const month = today.getMonth() - birthdate.getMonth();

  (month < 0 || (month === 0 && today.getDate() < birthdate.getDate())) && age--;

  return dateRegex.test(date) && age >= 18;
}

export const validateAccountOverlayInput = (name, value, data, setData) => {
  if (name === 'email') return validateEmail(value);
  if (name === 'password') {
    const passwordMismatch = data.confirm_password.touched && data.confirm_password.value !== value;
    if (passwordMismatch) {
      setData(prevState => ({ 
        ...prevState, 
        confirm_password: { ...prevState.confirm_password, valid: false } 
      }));
    } 
    return value.length >= 6;
  }
  if (name === 'confirm_password') return data.password.value === value;
  return !!value.length;
};

export const optionsGenderSelect = [
  { value: 'Women or non-binary femme CEO', label: 'Women or non-binary femme CEO' },
  {
    value: 'Women or non-binary femme-owned company (<51%)',
    label: 'Women or non-binary femme-owned company (<51%)',
  },
  {
    value: 'Majority Women or non-binary femme-owned company',
    label: 'Majority Women or non-binary femme-owned company',
  },
  {
    value: 'Women or non-binary femme-led company (C-Suite and other prominent position in the company)',
    label: 'Women or non-binary femme-led company (C-Suite and other prominent position in the company)',
  },
  {
    value: 'Gender Diverse (women represent 30%+ of the organization)',
    label: 'Gender Diverse (women represent 30%+ of the organization)',
  },
  {
    value: 'Led by Majority Black, Latino, Latina, or Latinx or POC team',
    label: 'Led by Majority Black, Latino, Latina, or Latinx or POC team',
  },
  { value: 'None of the above', label: 'None of the above' }];

export const optionsRaceSelect = [
  { value: 'African American or Black', label: 'African American or Black' },
  { value: 'Asian', label: 'Asian' },
  { value: 'Hispanic or Latino, Latina, or Latinx', label: 'Hispanic or Latino, Latina, or Latinx' },
  { value: 'Middle Eastern', label: 'Middle Eastern' },
  { value: 'Native or Indigenous', label: 'Native or Indigenous' },
  { value: 'Pacific Islander', label: 'Pacific Islander' },
  { value: 'White', label: 'White' },
  { value: 'Multi-Racial', label: 'Multi-Racial' },
  { value: 'Prefer not to say', label: 'Prefer not to say' },
];

export const optionsMilitaryAffiliationSelect = [
  { value: 'Active Duty', label: 'Active Duty' },
  { value: 'Military Family Member', label: 'Military Family Member' },
  { value: 'Military Spouse', label: 'Military Spouse' },
  { value: 'Veteran', label: 'Veteran' },
  { value: 'None', label: 'None' },
  { value: 'Prefer not to say', label: 'Prefer not to say' },
];

export const optionsLGTBSelect = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'Prefer not to say', label: 'Prefer not to say' }];

export const optionsDisabilitySelect = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'Prefer not to say', label: 'Prefer not to say' }];

export const optionsEmployeeQuantities = [
  { value: '0 (just myself)', label: '0 (just myself)' },
  { value: '1 to 5', label: '1 to 5' },
  { value: '6 to 10', label: '6 to 10' },
  { value: '11 to 50', label: '11 to 50' },
  { value: '51 to 100', label: '51 to 100' },
  { value: 'More than 100', label: 'More than 100' }];

export const optionsTimeInBusiness = [
  { value: 'Less than 6 months', label: 'Less than 6 months' },
  { value: 'More than 6 months, but less than 1 year', label: 'More than 6 months, but less than 1 year' },
  { value: 'More than 1 year, but less than 2 years', label: 'More than 1 year, but less than 2 years' },
  { value: 'More than 2 years', label: 'More than 2 years' }];

export const optionsRevenue = [
  { value: 'Less than $25K', label: 'Less than $25K' },
  { value: 'Between $25K and $50K', label: 'Between $25K and $50K' },
  { value: 'Between $50K and $100K', label: 'Between $50K and $100K' },
  { value: 'Between $100K and $250K', label: 'Between $100K and $250K' },
  { value: 'Between $250K and $500K', label: 'Between $250K and $500K' },
  { value: 'Between $500K and $1 Million', label: 'Between $500K and $1 Million' },
  { value: 'More than $1 Million', label: 'More than $1 Million' }];

export const optionsBusinessType = [
  { value: 'For-profit', label: 'For-profit' },
  { value: 'Nonprofit', label: 'Nonprofit' }
];

export const optionsEntityType = [
  { value: 'C Corporation', label: 'C Corporation' },
  { value: 'S Corporation', label: 'S Corporation' },
  { value: 'Partnership', label: 'Partnership' },
  { value: 'LLC', label: 'LLC' },
  { value: 'Sole Proprietor', label: 'Sole Proprietor' }
];

export const optionsMonthlyNetProfit = [
  { value: 'Between $0 and $500', label: 'Between $0 and $500' },
  { value: 'Between $500 and $1,000', label: 'Between $500 and $1,000' },
  { value: 'Between $1,000 and $2,500', label: 'Between $1,000 and $2,500' },
  { value: 'Between $2,500 and $5,000', label: 'Between $2,500 and $5,000' },
  { value: 'Between $5,000 and $10,000', label: 'Between $5,000 and $10,000' },
  { value: 'More than $10,000', label: 'More than $10,000' }];

export const optionsIndustry = [
  { value: 'Artificial Intelligence', label: 'Artificial Intelligence' },
  { value: 'B2B Business Products & Services', label: 'B2B Business Products & Services' },
  { value: 'Construction', label: 'Construction' },
  { value: 'Consumer Goods', label: 'Consumer Goods' },
  { value: 'Consumer Tech', label: 'Consumer Tech' },
  { value: 'Data & Analytics', label: 'Data & Analytics' },
  { value: 'E-Commerce', label: 'E-Commerce' },
  { value: 'Education', label: 'Education' },
  { value: 'Entertainment', label: 'Entertainment' },
  { value: 'Fashion', label: 'Fashion' },
  { value: 'Beauty', label: 'Beauty' },
  { value: 'Fem Tech', label: 'Fem Tech' },
  { value: 'Food & Beverage', label: 'Food & Beverage' },
  { value: 'Government Services', label: 'Government Services' },
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Logistics & Transportation', label: 'Logistics & Transportation' },
  { value: 'Manufacturing', label: 'Manufacturing' },
  { value: 'Media', label: 'Media' },
  { value: 'Mobile', label: 'Mobile' },
  { value: 'Real Estate', label: 'Real Estate' },
  { value: 'Restaurant or Food-Catering', label: 'Restaurant or Food-Catering' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Security', label: 'Security' },
  { value: 'Service-based', label: 'Service-based' },
  { value: 'Software', label: 'Software' }];

export const optionsSocialMediaFollowing = [
  { value: 'Not provided', label: 'Select...' },
  { value: 'Less than 1K', label: 'Less than 1K' },
  { value: '1K-10K', label: '1K-10K' },
  { value: '10K-50K', label: '10K-50K' },
  { value: '50K+', label: '50K+' }];

export const optionsSocialMediaChanels = [
  { value: 'Instagram', label: 'Instagram' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Twitter', label: 'Twitter' },
  { value: 'LinkedIn', label: 'LinkedIn' },
  { value: 'YouTube', label: 'YouTube' },
  { value: 'Snapchat', label: 'Snapchat' },
  { value: 'TikTok', label: 'TikTok' },
  { value: 'Blog', label: 'Blog' },
  { value: 'E-mail marketing', label: 'E-mail marketing' },
  { value: 'Organic or SEO', label: 'Organic or SEO' },
  { value: 'Paid Search,', label: 'Paid Search' },
  { value: 'Content Marketing', label: 'Content Marketing' },
  { value: 'Affiliates', label: 'Affiliates' },
  { value: 'Referrals', label: 'Referrals' },
  { value: 'Direct', label: 'Direct' }];

export const optionsMarketingBudget = [
  { value: 'Not provided', label: 'Select...' },
  { value: 'Less than $1K', label: 'Less than $1K' },
  { value: '$1K-$5K', label: '$1K-$5K' },
  { value: '$5K-$10K', label: '$5K-$10K' },
  { value: '$10K-$25K', label: '$10K-$25K' },
  { value: '$25K+', label: '$25K+' }];

export const optionsHowDidYouHearAboutUs = [
  { value: 'EnrichHER Website', label: 'EnrichHER Website' },
  { value: 'Hello Alice', label: 'Hello Alice' },
  { value: 'Founders of Color', label: 'Founders of Color' },
  { value: 'Biz Mujer', label: 'Biz Mujer' },
  { value: 'Black Innovation Alliance', label: 'Black Innovation Alliance' },
  { value: 'Clubhouse', label: 'Clubhouse' },
  { value: 'Center for Civic Innovation', label: 'Center for Civic Innovation' },
  { value: 'Tory Burch Foundation', label: 'Tory Burch Foundation' },
  { value: 'Intentional Life Club', label: 'Intentional Life Club' },
  { value: 'Internet', label: 'Internet' },
  { value: 'Radio', label: 'Radio' },
  { value: 'Podcast', label: 'Podcast' },
  { value: 'Social Media', label: 'Social Media' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Linkedin', label: 'Linkedin' },
  { value: 'Google', label: 'Google' },
  { value: 'Articles', label: 'Articles' },
  { value: 'YouTube', label: 'YouTube' },
  { value: 'Events', label: 'Events' },
  { value: 'Instagram', label: 'Instagram' },
  { value: 'Publication', label: 'Publication' },
  { value: 'News Article', label: 'News Article' },
  { value: 'Other', label: 'Other' }];

export const optionsBusinessYears = [
  {
    value: 0, label: '0 years',
  },
  {
    value: 0.5, label: '0.5 years',
  },
  {
    value: 1, label: '1 year',
  },
  {
    value: 1.5, label: '1.5 year',
  }, {
    value: 2, label: '2+ years',
  },
];


export function calculateAPR(creditScore) {
  if (creditScore >= 300 && creditScore <= 549)
    return 'DENIED';
  else if (creditScore >= 550 && creditScore <= 599)
    return 20;
  else if (creditScore >= 600 && creditScore <= 639)
    return 18;
  else if (creditScore >= 640 && creditScore <= 659)
    return 16;
  else if (creditScore >= 660 && creditScore <= 679)
    return 14;
  else if (creditScore >= 680 && creditScore <= 719)
    return 12;
  else if (creditScore >= 720 && creditScore <= 850)
    return 10;
}
