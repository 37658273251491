import React from 'react';
import IconCheck from '../../../Assets/icons/icon-check.svg';

const Paginate = ({ steps, currentStep }) => {
  const [width, setWidth] = React.useState(0);
  React.useEffect(() => {
    setWidth(window.innerWidth);
    const handlingSize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handlingSize);
    return () => window.removeEventListener('resize', handlingSize);
  }, []);
  return width > 768 ? (
    <div className='paginate-steps my-4'>
      {steps.map((item, i) => <div className='step' key={i}> <span>
        {item}
      </span>
        <div className='container-value'>
          <div className={`icon ${(currentStep > i || currentStep === steps.length - 1) && 'active'}`}>
            {currentStep > i || currentStep === steps.length - 1 ? <img src={IconCheck} alt='' /> : i + 1}
          </div>
        </div>
      </div>)}
    </div>) :
    (
      <div className='paginate-steps my-3'>
        {steps.map((item, i) => <div className='step' key={i}>
          <div className={`icon ${(currentStep > i || currentStep === steps.length - 1) && 'active'}`}>
            {currentStep > i || currentStep === steps.length - 1 ? <img src={IconCheck} alt='' /> : i + 1}
          </div>
          {currentStep === i && <span>
            {item}
          </span>}

        </div>)}
      </div>

    );

};

Paginate.defaultProps = {
  items: [],

};
export default Paginate;
