import React from 'react';


export const Error = ({error}) => {
  return (
    <div className='error-container my-3'>
      <p>
        {error}
      </p>
    </div>
  )
}
