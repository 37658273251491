import jwt from 'jsonwebtoken';

export const decodeJWT = (token) => {
  if (!token) return null;
  try {
    const { lead_id } = jwt.verify(token, process.env.REACT_APP_JWT_PRIVATE_KEY);
    return lead_id;
  } catch {
    return null;
  }
}


export const setQueryParams = (queryParams, data, setData) => {
  let params = queryParams.toString().replace('%40', '@').split('&');
  if (params?.[0]) {
    for (let param of params) {
      let [name, value] = param.split('=');
      if (name === "two_or_more_employees+" && value === 'Yes') {
        data["two_or_more_employees"] = true;
      } else if (name === "business_based_in_the_us" && value === 'Yes') {
        data["business_based_in_the_us"] = true;
      } else if (name === "us_citizen" && value === 'Yes') {
        data["us_citizen"] = true;
      } else if (name === "amount") {
        let num = parseInt(value)
        data["loan_amount_requested"] = num < 10000 || isNaN(num) ? 10000 : num > 250000 ? 250000 : num;
      } else if (name === "credit_score") {
        let num = parseInt(value)
        data["credit_score"] = num < 590 || isNaN(num) ? 590 : num > 850 ? 850 : num;
      } else {
        data[name] = value ? value : '';
      }
    }
    setData(data)
  }
}