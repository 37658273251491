import React from 'react'

const links = [
    {
        title: 'Privacy Policy',
        url: `${process.env.REACT_APP_BASE_WP_URL}privacy`
    },
    {
        title: 'Terms of Use',
        url: `${process.env.REACT_APP_BASE_WP_URL}terms`
    },
    {
        title: 'Legal Notice',
        url: `${process.env.REACT_APP_BASE_WP_URL}legal`
    }
]

const disclaimers = [
    `There are no warranties implied.`,
    `EnrichHER Inc. “EnrichHER” website is not affiliated with EnrichHER Funding LLC, a Funding Portal registered with the U.S. Securities and
    Exchange Commission (the “SEC) for the purpose of offering and selling securities in accordance with the exemption from securities 
    registration requirements contained in Section 4(a)(6) of the Securities Act of 1933 and the regulations promulgated by the SEC. 
    EnrichHER’s website is limited to the dissemination of general information pertaining to its membership services. Accordingly, the
    publication of EnrichHER’s website on the Internet should not be construed by any consumer and/or prospective client as EnrichHER’s 
    solicitation to affect, or attempt to affect transactions in securities, or the rendering of personalized investment advice for compensation,
    over the Internet. EnrichHER Inc. does not make any representations or warranties as to the accuracy, timeliness, suitability, completeness,
    or relevance of any information prepared by any unaffiliated third party, whether linked to EnrichHER’s website or incorporated herein, 
    and takes no responsibility therefor. All such information herein is provided solely for convenience purposes only and all users thereof
    should be guided accordingly.`,
    `This website and information are provided for guidance and information purposes only. Investments involve risk and unless otherwise
    stated, are not guaranteed. Be sure to first consult with a qualified financial adviser and/or tax professional before implementing any
    strategy. This website and information are not intended to provide investment, tax, or legal advice.`,
    `There are risks associated with investing in a business. Investing in business involves risk of loss. Loss of principal is possible. 
    A business's past performance is not a guarantee or predictor of future performance.`,
    `EnrichHER is presently not available to borrowers in the following states: California, Connecticut, District of Columbia (DC), New York, Vermont.`,
    `EnrichHER is not presently available to borrowers in the following industry: adult entertainment, drug dispensaries, firearm, gambling,
    money service business, political, and religious entities.`
]

const BottomFooter = () => {
    return (
        <div className='footer_bottom_holder'>
            <div className='footer_bottom_border' />
            <div className='footer_bottom'>
                <div>
                    <p className='my-0 py-0'>© 2021 Copyright EnrichHER Inc.</p>
                </div>
                <div className='textwidget'>
                    <p className='my-0 py-0'>
                        {links.map((item, index) =>
                            <span key={index}>
                                <a href={item.url} target='_self' rel='noopener noreferrer'>
                                    {item.title}
                                </a>
                                {(index !== links.length - 1) && <>&nbsp;|&nbsp;</>}
                            </span>
                        )}
                    </p>
                </div>
                <div className='textwidget'>
                    <div className='disclaimer-footer'>
                        {disclaimers.map(disclaimer =>
                            <p key={disclaimer}> {disclaimer} </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BottomFooter
