import React, { useState, useEffect, useRef } from 'react';
import { API } from 'aws-amplify';
import { AccountOverlay } from '../../AccountOverlay';
//Components
import { ModalLayout } from '../../../Layouts/ModalLayout';
import { Modal } from '../../Modal';
import { RegisteredUser as RegisteredUserModalContent } from '../../../Utils/modalsContent';
import Paginate from './Paginate';
import { FormCheckQualification } from './CheckQualification';
import { FormPersonalInformation } from './PersonalInformation';
import { FormFinancialInformation } from './FinancialInformation';
import { FormMarketing } from './Marketing';
import { FormTermsAgreement } from './TermsAgreement';
import { ApplicationFee } from './ApplicationFee';
//constants
import { steps } from './constants';



const FormApplication = ({
  steps,
  state,
  withSubscription,
  onSubmit,
  hasSubscription,
  setHasSubscription,
  completedToryBurchApplication,
  step,
  ...rest
}) => {
  const [currentStep, setCurrentStep] = useState(step);
  const [modalShowing, setModalShowing] = useState('None');
  const refWrapper = useRef()

  useEffect(() => {
    setCurrentStep(step);
  }, [step])

  useEffect(() => {
    if (refWrapper) {
      document.querySelector('body').scrollTo(0, 0);
      refWrapper.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
    return () => {
      rest.setError('');
    };
  }, [currentStep]);

  const nextStep = () => {
    if (currentStep + 1 < steps.length) setCurrentStep(currentStep + 1);
  };

  const backStep = (event) => {
    event.preventDefault();
    if (currentStep > 0) setCurrentStep(currentStep - 1);
  };

  const verifySubscription = async () => {
    rest.setIsLoading(true);
    try {
      const response = await API.get('enrichHer-mvp', 'getuserdata', {
        queryStringParameters: {
          email: rest.values.email,
        },
      });
      if (response) setHasSubscription(response.has_active_subscription);
    } catch (err) {
      rest.setError('Error');
    }
    rest.setIsLoading(false);
  };

  const handleSubmit = (isSaveAndContinue, page) => async (event) => {
    event.preventDefault();
    try {
      rest.setIsLoading(true);
      if (page === 'termsAndConditions') {
        await verifySubscription();
      } else {
        const step = isSaveAndContinue ? currentStep + 1 : currentStep;
        const payload = { ...rest.values, step, page };
        await API.post('enrichHer-mvp', 'saveApplicationFormData', { body: payload });
      }

      if (isSaveAndContinue) {
        nextStep()
      } else {
        const queryParams = { email: rest.values.email, timestamp: new Date().getTime() };
        const response = await API.get('wordpress', 'wp-json/user_manager/v1/user', { queryStringParameters: queryParams });
        const modalName = response?.user?.ID ? 'Registered User' : 'Account Overlay';
        setModalShowing(modalName);
      }
    } catch {
      rest.setError('Sorry there was an error, please try again');
    } finally {
      rest.setIsLoading(false);
    }
  }

  return (
    <>
      <ModalLayout isOpen={modalShowing !== 'None'}>
        <AccountOverlay
          name={`${rest.values.first_name} ${rest.values.last_name}`}
          email={rest.values.email}
          show={modalShowing === 'Account Overlay'}
        />
        <Modal 
          content={RegisteredUserModalContent} 
          show={modalShowing === 'Registered User'} 
          onClick={() => window.location.replace(process.env.REACT_APP_BASE_WP_URL + '/dashboard/')} 
        />
      </ModalLayout>
      <Paginate steps={steps} currentStep={currentStep} />
      <div ref={refWrapper} className='container-form-application'>
        <form onSubmit={onSubmit} className='my-3'>
          <div className='wrapper-forms'>
            <div className={'scope-form'} style={{ transform: `translate3d(${-currentStep * 100}%,0,0)` }}>
              <FormCheckQualification disabled={currentStep !== 0} state={state}
                currentStep={currentStep} nextStep={nextStep} backStep={backStep} setModalShowing={setModalShowing}
                completedToryBurchApplication={completedToryBurchApplication} {...rest} />
              <FormPersonalInformation disabled={currentStep !== 1} state={state}
                currentStep={currentStep} nextStep={nextStep} backStep={backStep}
                handleSubmit={handleSubmit} {...rest} />
              <FormFinancialInformation disabled={currentStep !== 2} state={state}
                currentStep={currentStep} nextStep={nextStep} backStep={backStep}
                handleSubmit={handleSubmit} {...rest} />
              <FormMarketing disabled={currentStep !== 3} state={state}
                currentStep={currentStep} nextStep={nextStep} backStep={backStep}
                handleSubmit={handleSubmit} {...rest} />
              <FormTermsAgreement disabled={currentStep !== 4} state={state}
                currentStep={currentStep} nextStep={nextStep} backStep={backStep}
                handleSubmit={handleSubmit} verifySubscription={verifySubscription} {...rest} />
              <ApplicationFee disabled={currentStep !== 5} state={state}
                currentStep={currentStep} nextStep={nextStep} backStep={backStep}
                hasSubscription={hasSubscription} {...rest} />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

FormApplication.defaultProps =
{
  steps,
};

export default FormApplication;
