export const RegisteredUser = {
    title: "WHAT'S NEXT?",
    body: <p>Your progress was saved, in order to continue it you can <a href={`${process.env.REACT_APP_BASE_WP_URL}login`} target="_blank" rel="noreferrer" >login</a> and visit your applications <a href={`${process.env.REACT_APP_BASE_WP_URL}dashboard/?to_dashboard=company`} target="_blank" rel="noreferrer" >dashboard</a> where you can review all the applications submitted.</p>,
    btnText: "OK"
};

export const ToryBurch = {
    title: "Congratulations!",
    body: "You’re Pre-Qualified for the [Insert Program Name]. You are about to proceed to the next step, our funding application. Let’s keep the momentum going!",
    btnText: "OK"
};