import React from 'react'

const TopFooterItem = ({ title, url }) => {
    return (
        <p className='p-0 m-0'>
            <a href={url}>{title}</a>
        </p>
    )
}

export default TopFooterItem
