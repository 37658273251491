import React from 'react'
import TopFooterSocialMedia from './TopFooterSocialMedia'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

const TopFooterConnectWithUs = ({ values }) => {

    return (
        <>
            <TopFooterSocialMedia />
            <div className='d-flex'>
                <div className='icon_holder '>
                    <FontAwesomeIcon icon={faPhoneAlt}/>
                </div>
                <div className='icon_text_holder pl-2'>
                    <p style={{marginBottom: 0}}>404-721-0216</p>
                </div>
            </div>
            {values.map(option => {
                return (
                    <div key={option.icon} className='d-flex my-2'>
                        <div className='icon_holder '>
                            <a itemProp='url' href={option.url} target='_blank' className='q_icon_link'>
                                <span
                                    data-icon-type='normal'
                                    style={{ fontSize: '16px' }}
                                    className='qode_iwt_icon_holder q_font_awsome_icon fa-lg  '>
                                    <span aria-hidden='true'
                                        className={option.icon}
                                        style={{ color: '#ffffff' }} />
                                </span>
                            </a>
                        </div>
                        <div className='icon_text_holder pl-2'>
                            <a itemProp='url' className='icon_with_title_link' href={option.url} target='_blank' style={{ color: '#ffffff' }}>
                                {option.text}
                            </a>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default TopFooterConnectWithUs
