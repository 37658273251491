export const clickwrapsTypesOfFunding = {
  "Loan": process.env['REACT_APP_DOCUSIGN_LOAN_CLICKWRAP'],
  "Grant": process.env['REACT_APP_DOCUSIGN_GRANT_CLICKWRAP'],
  "All EnrichHER Funding Options": process.env['REACT_APP_DOCUSIGN_LOAN_AND_GRANT_CLICKWRAP']
};

export const fundingOptions = {
  grant: 'Grant',
  loan: 'Loan',
  allFunding: 'All EnrichHER Funding Options',
  toryBurch: 'Tory Burch Loan Program',
};

export const steps = [
  'Check Qualification', 'Personal Information', 'Finance', 'Marketing', 'Terms Agreement', 'Application Fee',
];

export const state = {
  data: {
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    home_address: "",
    home_address_2: "",
    home_city: "",
    home_state: "",
    home_zip_code: "",
    gender: "",
    race: "",
    military_affiliation: "",
    do_you_identify_as_lgbtq: "",
    individual_with_disabilities: "",
    company_name: "",
    company_website: "",
    company_city: "",
    company_state: "",
    company_address: "",
    company_address_2: "",
    company_zip_code: "",
    employee_number: "",
    time_in_business: "",
    business_revenue: "",
    net_profit: "",
    business_industry: "",
    remaining_credit_card_balance: null,
    defaulted_on_debt: null,
    bankruptcy: null,
    how_did_you_hear_about_us: "",
    total_social_media_following: "",
    social_media_channels: "",
    monthly_marketing_budget: "",
    terms_and_conditions: false,
    credit_score: 760,
    loan_amount_requested: 50000,
    us_citizen: null,
    business_based_in_the_us: null,
    two_or_more_employees: null,
    submitted_at: '',
    typeOfFunding: '',
    grantSelected: false,
    toryBurchSelected: false,
    utm_campaign: '',
    utm_source: '',
    utm_medium: ''
  },
  validations: {
    first_name: true,
    last_name: true,
    phone_number: true,
    home_address: true,
    home_address_2: true,
    home_city: true,
    home_state: true,
    home_zip_code: true,
    gender: true,
    race: true,
    military_affiliation: true,
    do_you_identify_as_lgbtq: true,
    individual_with_disabilities: true,
    company_address: true,
    company_city: true,
    company_state: true,
    company_zip_code: true,
    company_name: true,
    company_website: true,
    employee_number: true,
    time_in_business: true,
    business_revenue: true,
    net_profit: true,
    business_industry: true,
    remaining_credit_card_balance: true,
    bankruptcy: true,
    defaulted_on_debt: true,
    how_did_you_hear_about_us: true,
    terms_and_conditions: true,
    requested_amount: true,
    utm_campaign: true,
    utm_source: true,
    utm_medium: true
  }
}

export const defualtCheckboxOptions = [
  'us_citizen', 
  'business_based_in_the_us', 
  'two_or_more_employees', 
  'type_of_funding',
  'remaining_credit_card_balance', 
  'defaulted_on_debt', 
  'bankruptcy'
]

export const grantCheckboxOptions = [
  'us_citizen', 
  'business_based_in_the_us', 
  'type_of_funding',
]

export const defaultOptionalInputs = [
  'company_city', 
  'company_state', 
  'company_zip_code', 
  'company_address_2', 
  'home_address_2',
  'social_media_channels', 
  'total_social_media_following', 
  'monthly_marketing_budget', 
  'grantSelected', 
  'toryBurchSelected',
  'utm_campaign',
  'utm_source',
  'utm_medium'
]

export const grantOptionalInputs = [
  'company_address', 
  'company_city', 
  'company_state', 
  'company_zip_code', 
  'company_address_2',
  'net_profit', 
  'remaining_credit_card_balance',
  'defaulted_on_debt', 
  'bankruptcy', 
  'home_address_2',       
  'social_media_channels', 
  'total_social_media_following', 
  'monthly_marketing_budget', 
  'typeOfFunding', 
  'grantSelected', 
  'two_or_more_employees', 
  'toryBurchSelected',
  'utm_campaign',
  'utm_source',
  'utm_medium'
]

const defaultInputValue = { value: '', valid: false, touched: false };

export const defaultInputValues = {
  full_name: { ...defaultInputValue },
  company: { ...defaultInputValue },
  email: { ...defaultInputValue },
  password: { ...defaultInputValue },
  confirm_password: { ...defaultInputValue },
  dashboard: { ...defaultInputValue },
  terms_and_conditions: { ...defaultInputValue },
};