import React from 'react';
import { ERHButton } from '../../Button';
import { Error } from './Error';


export const ApplicationFee = ({ backStep, error, hasSubscription }) => (
  <div className={'fragment-form-application'}>
    <h3 className='title-form my-3'> Application FEE </h3>
    <div className='container-text my-4'>
      <p>
        {hasSubscription ?
          'As a paid member you are exempted from paying the application fee. ' 
          :
          'Almost there! Please proceed to checkout to submit your application fee.'
        }
      </p>
    </div>
    {error && <Error error={error} />}
    <div className='d-flex align-items-center flex-grow-1 justify-content-between my-2 flex-wrap'>
      <ERHButton className='my-2' onClick={backStep} outline> Back </ERHButton>
      {hasSubscription ? 
        <ERHButton className='my-2' type={'submit'}> SUBMIT </ERHButton> 
        :
        <ERHButton style={{width:'15.4375rem'}} type={'submit'}> PROCEED TO CHECKOUT </ERHButton>
      }
    </div>
  </div>
);