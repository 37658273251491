import React from 'react';

export function ERHRangeSlider({
                                 classInput = '',
                                 classLabel = '',
                                 classContainer = '',
                                 label,
                                 valueShow,
                                 type,
                                 ...rest
                               }) {
  return (
    <div className={classContainer}>
      {label && <label className={`label-input ${classLabel}`} htmlFor={rest.id}> {label} </label>}
      <input type='range' {...rest} className={`ERH-range-slider ${classInput}`}
      />
      {valueShow && <div className={'value-range-slider'}>
        {valueShow}

      </div>}
    </div>);
}
