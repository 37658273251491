import React from 'react';
import Select from 'react-select';
import { Tooltip } from 'reactstrap';


const formatSelectValues = (isMulti, values) => {
  if (isMulti && Array.isArray(values)) {
    return values.length ? values.map(value => { return { label: value, value } }) : null;
  }
  return values?.length ? { label: values, value: values } : null;
}

export const ERHSelect = ({
                            label,
                            value = '', 
                            placeholder = 'Select option',
                            classContainer = '',
                            classLabel = '',
                            classSelect = '',
                            classNamePrefix,
                            isMulti = false,
                            ...rest
                          }) => {
  return (
    <div className={classContainer}>
      <label className={'label-input'} htmlFor={rest.id}>
        {label}
      </label>
      <Select 
        placeholder={placeholder} 
        classNamePrefix='select' 
        className={`ERH-select ${classSelect}`} 
        isMulti={isMulti}
        value={formatSelectValues(isMulti, value)}
        {...rest} 
      />
    </div>
  );
};
export const ERHSelectTooltip = ({
                            label,
                            value = '', 
                            tooltip,
                            placeholder = 'Select option',
                            classContainer = '',
                            classLabel = '',
                            classSelect = '',
                            classNamePrefix,
                            isMulti = false,
                            ...rest
                          }) => {

  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <div className={classContainer}>
      <Tooltip placement="top" isOpen={tooltipOpen} target={`tooltip-${rest.id}`} toggle={toggle} >
        {tooltip}
      </Tooltip>
      <label id={`tooltip-${rest.id}`} className={'label-input'} htmlFor={rest.id}>
        {label}
      </label>
      <Select 
        placeholder={placeholder} 
        classNamePrefix='select' 
        className={`ERH-select ${classSelect}`}
        isMulti={isMulti} 
        value={formatSelectValues(isMulti, value)}
        {...rest} 
      />
    </div>
  );
};


