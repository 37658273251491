import { fundingOptions } from '../Components/Forms/FormApplication/constants';


export const getIneligibilityPage = (basedInTheUS, typeOfFunding) => {
    const baseURL = `https://${process.env.REACT_APP_WP_URL}`;
    let path = basedInTheUS ? '/ineligibility' : '/ineligibility-international';
    
    switch (typeOfFunding) {
      case fundingOptions.grant:
        path += '?apply=3';
        break;
      case fundingOptions.loan:
        path += '?apply=2';
        break;
      case fundingOptions.allFunding:
        path += '?apply=1';
        break;
      default:
        path += '';
    }

    return baseURL + path;
  }