import React, { useState, useLayoutEffect } from 'react';
import { clickwrapsTypesOfFunding } from '../../../Components/Forms/FormApplication/constants'


const DocusignClickWrap = (props) => {
  const [mustAgree, setMustAgree] = useState(false);

  useLayoutEffect(() => {
    window.docuSignClick.Clickwrap.render({
      environment: process.env['REACT_APP_DOCUSIGN_CLICKWRAP_SDK_SOURCE'],
      accountId: process.env['REACT_APP_DOCUSIGN_ACC_ID'],
      clickwrapId: clickwrapsTypesOfFunding[props.typeOfFunding],
      clientUserId: props.email,

      onMustAgree(agreement) {
        setMustAgree(true);
      },

      onAgreed(agreement) {
        props.accepted(true);
      },

      onDeclined(agreement) {
        // Called when the clientUserId has declined successfully
      }
    }, `#${props.id}`);

  }, [props.email, props.typeOfFunding]);

  return (
    <>
      <div id={props.id} />
      {!mustAgree && <p className='label-input mb-0 mt-2'>Terms already accepted, please continue.</p>}
    </>
  );
};

export default DocusignClickWrap;