import React, { useEffect, useState } from 'react';
import JWT from 'jsonwebtoken';
import { API } from 'aws-amplify';
import { Col, Row } from 'reactstrap';
import { ERHInput } from './Input';
import { ERHRadioBtn } from './RadioButton';
import { ERHCheckbox } from './Checkbox';
import { ERHButton } from './Button';
import { validateAccountOverlayInput as validateInput } from '../helpers';
import { defaultInputValues } from './Forms/FormApplication/constants';
import { LoadingPage } from './Loading';
import { handleGrantTextNotification } from '../services';


export const AccountOverlay = ({ name, email, show }) => {
  const [data, setData] = useState(defaultInputValues);
  const [loader, setLoader] = useState({ loading: false, height: '100%' });
  const [errorMessage, setErrorMessage] = useState('');
  const [formCompleted, setFormCompleted] = useState(false);

  useEffect(() => {
    //* Prepopulate name and email
    if (show) {
      setData(prevState => ({
        ...prevState,
        'full_name': { value: name, valid: true, touched: true },
        'email': { value: email, valid: true, touched: true }
      }));
      //* Set loader height
      const modalHeight = document.getElementById('account-overlay').scrollHeight;
      setLoader(prevState => ({ ...prevState, height: `${modalHeight}px` }));
    }
  }, []);

  useEffect(() => setFormCompleted(Object.values(data).every(field => field.valid)), [data]);

  const setInputValues = (name, value) => {
    const valid = validateInput(name, value, data, setData);
    setData(prevState => ({ ...prevState, [name]: { value, valid, touched: true } }));
  };

  const handleInputFocus = (event) => {
    //* Prevent autocomplete
    event.target.setAttribute('autocomplete', 'new-password');
  };

  const handleInputClick = (event) => {
    const { name, checked, value, type } = event.target;
    type === 'radio' && setData(prevState => ({ ...prevState, [name]: { value, valid: true, touched: true } }));
    type === 'checkbox' && setData(prevState => ({ ...prevState, [name]: { value: checked, valid: checked, touched: true } }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    data[name].touched && setInputValues(name, value.trim());
  };

  const handleInputBlur = (event) => {
    const { name, value } = event.target;
    !data[name].touched && setInputValues(name, value.trim());
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setLoader(prevState => ({ ...prevState, loading: true }));
    try {
      const payload = {
        name: data.full_name.value,
        email: data.email.value,
        password: data.password.value,
        company: data.company.value,
        dashboard: data.dashboard.value,
        notification: 'RESUME_YOUR_APPLICATION',
      };
      const body = JWT.sign(payload, process.env.REACT_APP_JWT_PRIVATE_KEY);
      const response = await API.post('wordpress', '/wp-json/user_manager/v1/create_user_from_checkout', { body });
      if (response === 'user exists') {
        setErrorMessage('Email already exists.')
        setLoader(prevState => ({ ...prevState, loading: false }));
      } else {
        await handleGrantTextNotification({ type: 'RESUME_YOUR_APPLICATION', email: data.email.value });
        window.location.assign(`${process.env.REACT_APP_BASE_WP_URL}login`);
      }
    } catch {
      setErrorMessage('Sorry there was an error, please try again');
      setLoader(prevState => ({ ...prevState, loading: false }));
    }
  };

  if (!show) return null;
  return (
    <>
      <div className="account-overlay" id="account-overlay">
        <LoadingPage isLoading={loader.loading} height={loader.height} />
        <div className="ml-2 mb-3 pl-1">
          <h1>CREATE YOUR ACCOUNT</h1>
          <p className="mb-0 mt-3">Create your account in less than 5 minutes.</p>
        </div>
        <Row>
          <Col xs={12}>
            <ERHInput
              type='text' id='full-name' name='full_name' label='Full Name *'
              placeholder='Your Full Name' classContainer={'mt-3'}
              onFocus={handleInputFocus}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              value={data.full_name.value} disabled
            />
          </Col>
          <Col xs={12} sm={6}>
            <ERHInput
              type='email' id='email' name='email' label='Email Address *'
              placeholder='Email Address' classContainer={'mt-3'}
              onFocus={handleInputFocus}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              value={data.email.value} disabled
            />
          </Col>
          <Col xs={12} sm={6}>
            <ERHInput
              type='text' id='company-name' name='company' label='Company Name *'
              placeholder='Company Name' classContainer={'mt-3'}
              onFocus={handleInputFocus}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              error={data.company.touched && !data.company.valid && 'Please enter a valid name.'}
            />
          </Col>
          <Col xs={12} sm={6}>
            <ERHInput
              type='password' id='password' name='password' label='Password *'
              placeholder='Password' classContainer={'mt-3'}
              onFocus={handleInputFocus}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              error={data.password.touched && !data.password.valid && 'Password length must be atleast 6 characters.'}
            />
          </Col>
          <Col xs={12} sm={6}>
            <ERHInput
              type='password' id='confirm-password' name='confirm_password' label='Confirm Password *'
              placeholder='Confirm Password' classContainer={'mt-3'}
              onFocus={handleInputFocus}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              error={data.confirm_password.touched && !data.confirm_password.valid && 'Password and confirm password not matched.'}
            />
          </Col>
          <Col xs={12}>
            <p className='label-input mb-0 mt-3'>
              What dashboard do you want to start with? (You can always change this setting later.) *
            </p>
            <div className='d-flex flex-wrap'>
              <div className='col-radio-btn'>
                <ERHRadioBtn
                  id='funder-dashboard' name='dashboard' label='Funder Dashboard'
                  classContainer='mr-4 mt-3 w-auto' onClick={handleInputClick}
                  checked={data.dashboard.value === 'Funder'} value={'Funder'}
                />
              </div>
              <div className='col-radio-btn'>
                <ERHRadioBtn
                  id='company-dashboard' name='dashboard' label='Company Dashboard'
                  classContainer='mr-4 mt-3 w-auto' onClick={handleInputClick}
                  checked={data.dashboard.value === 'Company'} value={'Company'}
                />
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className="d-flex mt-3">
              <ERHCheckbox
                id='terms-and-conditions' name='terms_and_conditions'
                styled={true} classContainer={'my-1 mr-1'}
                onClick={handleInputClick}
              />
              <p>
                Check box to accept the <a href={`${process.env.REACT_APP_BASE_WP_URL}terms`}><b>Terms of Use&nbsp;</b></a>
                and have read the <a href={`${process.env.REACT_APP_BASE_WP_URL}privacy`}><b>Privacy Policy</b></a>
              </p>
            </div>
          </Col>
          <Col xs={12}>
            <ERHButton className='mt-4 w-100' onClick={handleSubmit} disabled={!formCompleted} >
              CREATE AN ACCOUNT
            </ERHButton>
            {!!errorMessage &&
              <div className="error-container mt-2">
                <p>{errorMessage}</p>
              </div>
            }
          </Col>
          <Col xs={12}>
            <div className="d-flex justify-content-center mt-4">
              <a href={`${process.env.REACT_APP_BASE_WP_URL}login`} >
                Already have an account? <b>Login</b>
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
