import React from 'react';
import { API } from 'aws-amplify';
import { ERHInput } from '../../Input';
import { Col, Row } from 'reactstrap';
import { ERHSelect, ERHSelectTooltip } from '../../Select.jsx';
import { ERHRadioBtn } from '../../RadioButton';
import { ERHButton } from '../../Button';
import { optionsEmployeeQuantities, optionsIndustry, optionsMonthlyNetProfit, optionsRevenue, optionsEntityType } from '../../../helpers';
import { Error } from './Error';
import { optionsBusinessType } from '../../../helpers';

export const FormFinancialInformation = ({
  backStep, nextStep, currentStep,
  handleSelectInputChange,
  values,
  setIsLoading,
  error,
  setError,
  validations,
  touched,
  handleSubmit,
  ...rest
}) => {
  const {
    company_name,
    company_website,
    company_address,
    company_address_2,
    company_city,
    company_state,
    company_zip_code,
    employee_number,
    time_in_business,
    business_revenue,
    net_profit,
    business_industry,
    business_entity_type,
    business_type,
    remaining_credit_card_balance, 
    defaulted_on_debt, 
    bankruptcy,
  } = validations;
  
  const isCompleteStep = () => {
    if (!rest.disabled) {
      if (values.grantSelected) {
        return (
          company_name 
          && company_website 
          && business_entity_type 
          && business_type
          && employee_number
          && business_revenue
          && business_industry
        )
      }
      return (
        company_name
        && company_website
        && employee_number
        && time_in_business
        && business_revenue
        && net_profit
        && business_industry
        && company_address
        && company_address_2
        && company_city
        && company_state
        && company_zip_code
        && business_entity_type
        && business_type
        && remaining_credit_card_balance 
        && defaulted_on_debt
        && bankruptcy
      )
    }
    return false;
  };

  const stepComplete = React.useMemo(isCompleteStep, [
    company_name,
    company_website,
    employee_number,
    time_in_business,
    business_revenue,
    net_profit,
    company_address,
    company_address_2,
    company_city,
    company_state,
    company_zip_code,
    business_industry,
    business_entity_type,
    business_type,
    remaining_credit_card_balance, 
    defaulted_on_debt, 
    bankruptcy,
    rest.disabled
  ]);

  
  return (
    <div className={'fragment-form-application'}>
      <h3 className='title-form my-3'> Financing information </h3>
      <div className='form'>
        <Row>
          <Col xs={12} sm={6}>
            <ERHInput name='company_name' value={values.company_name} type='text' id='company_name'
              label='Company name*'
              placeholder='Company name'
              classContainer={'my-2'} {...rest} />
          </Col>
          <Col xs={12} sm={6}>
            <ERHInput name='company_website' value={values.company_website} type='text' id='company_website'
              label='Company website URL*' placeholder='Website URL'
              classContainer={'my-2'}
              error={touched.company_website && !validations.company_website && 'Please enter a valid URL starting with http://'}
              {...rest} />
          </Col>
          {!values.grantSelected &&
            <>
              <Col xs={12}>
                <ERHInput value={values.company_address} name='company_address' type='text' id='company_address'
                  label='Company address 1*' placeholder='Enter company address'
                  classContainer={'my-2'} {...rest} />
              </Col>
              <Col xs={12}>
                <ERHInput value={values.company_address_2} type='text' id='company_address_2' name='company_address_2'
                  label='Company address 2' placeholder='Enter company address' classContainer={'my-2'} {...rest} />
              </Col>
              <Col xs={12} sm={6} md={4}>
                <ERHInput name='company_city' value={values.company_city} type='text' id='company_city' label='City'
                  placeholder='City' classContainer={'my-2'} {...rest} />
              </Col>
              <Col xs={12} sm={6} md={4}>
                <ERHInput name={'company_state'} value={values.company_state} type='text' id='company_state' label='State'
                  placeholder='State' classContainer={'my-2'} {...rest} />
              </Col>
              <Col xs={12} sm={6} md={4}>
                <ERHInput
                  type='number'
                  name={'company_zip_code'}
                  value={values.company_zip_code}
                  id='company_zip_code'
                  label='Zip Code'
                  placeholder='Zip Code'
                  classContainer={'my-2'} {...rest} />
              </Col>
            </>
          }
          <Col xs={12}>
            <ERHSelectTooltip
              tooltip='Please select your business entity type.'
              name='business_entity_type' options={optionsEntityType} onChange={handleSelectInputChange}
              id='business_entity_type' label='Business Entity Type*'
              classContainer={'my-2'} value={values.business_entity_type} />
          </Col>
          <Col xs={12}>
            <ERHSelectTooltip
              tooltip='Select your business type'
              name='business_type'
              options={optionsBusinessType}
              id='business_type'
              classContainer={'my-2'}
              label='What is your business type?*' 
              value={values.business_type}
              onChange={handleSelectInputChange} />
          </Col>
          <Col xs={12}>
            <ERHSelectTooltip
              tooltip='Please select the total number of people that help you run your business. Full time and part-time employees and contractors all count equally.'
              name='employee_number' options={optionsEmployeeQuantities} onChange={handleSelectInputChange}
              id='employee_number' label='How many employees and contractors does your company have?*'
              classContainer={'my-2'} value={values.employee_number} />
          </Col>
          <Col xs={12}>
            <ERHSelect name='business_revenue' id='business_revenue'
              label='What is your business revenue over the past 12 month?*'
              classContainer={'my-2'} options={optionsRevenue} onChange={handleSelectInputChange}
              value={values.business_revenue} />
          </Col>
          {!values.grantSelected &&
            <Col xs={12}>
              <ERHSelect options={optionsMonthlyNetProfit} id='net_profit'
                label='What is your monthly average net profit for the past 3 months?*'
                classContainer={'my-2'} onChange={handleSelectInputChange} name='net_profit'
                value={values.net_profit}
              />
            </Col>
          }
          <Col xs={12}>
            <ERHSelect isMulti name='business_industry' options={optionsIndustry} id='business_industry'
              label='What industry is your business in?* (Select all options that apply)'
              classContainer={'my-2'} onChange={handleSelectInputChange} value={values.business_industry} />
          </Col>
          {!values.grantSelected &&
            <>
              <Col xs={12}>
                <p className='label-input mb-0 mt-2'> When paying for your credit card every month, is your remaining
                balance less than 35% of your credit limit?* </p>
                <div className='d-flex flex-wrap'>
                  <div className='col-radio-btn'>
                    <ERHRadioBtn name='remaining_credit_card_balance' classContainer='mr-2 my-2' label='Yes, it is'
                      negative={!values.remaining_credit_card_balance} checked={values.remaining_credit_card_balance !== null && !!values.remaining_credit_card_balance}
                      id='yes_credit_card_balance'
                      {...rest}
                      value={true} />
                  </div>
                  <div className='col-radio-btn'>
                    <ERHRadioBtn name='remaining_credit_card_balance' classContainer='mr-2 my-2' label='No, it isn’t'
                      negative={!values.remaining_credit_card_balance} checked={values.remaining_credit_card_balance !== null && !values.remaining_credit_card_balance}
                      id='no_credit_card_balance' {...rest} value={false} />
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <p className='label-input mb-0 mt-2'> Have you ever defaulted on debt in the past 3 years?* </p>
                <div className='d-flex flex-wrap'>
                  <div className='col-radio-btn'>
                    <ERHRadioBtn classContainer='mr-2 my-2' label='Yes' 
                      name='defaulted_on_debt' checked={values.defaulted_on_debt !== null && !!values.defaulted_on_debt}
                      id='defaulted_on_debt_yes' {...rest} value={true} />
                  </div>
                  <div className='col-radio-btn'>
                    <ERHRadioBtn classContainer='mr-2 my-2' label='No'
                      name='defaulted_on_debt' checked={values.defaulted_on_debt !== null && !values.defaulted_on_debt}
                      id='defaulted_on_debt_no' {...rest} value={false} /></div>
                </div>
              </Col>
              <Col xs={12}>
                <p className='label-input mb-0 mt-2'> Have you declared bankruptcy in the past 3 years?* </p>
                <div className='d-flex flex-wrap'>
                  <div className='col-radio-btn'>
                    <ERHRadioBtn classContainer='mr-2 my-2' label='Yes'
                      name='bankruptcy' checked={values.bankruptcy !== null && !!values.bankruptcy}
                      id='bankruptcy_yes_' {...rest} value={true} />
                  </div>
                  <div className='col-radio-btn'>
                    <ERHRadioBtn classContainer='mr-2 my-2' label='No'
                      name='bankruptcy' checked={values.bankruptcy !== null && !values.bankruptcy}
                      id='bankruptcy_no' {...rest} value={false} /></div>
                </div>
              </Col>
            </>
          }
        </Row>
      </div>
      {error && <Error error={error} />}
      <Row className="my-4">
          <Col xs="12" md="4">
            <ERHButton className='my-2' onClick={backStep} outline> Back </ERHButton>
          </Col>
          <Col xs="12" md="8" className="d-flex justify-content-end flex-wrap">
              <ERHButton 
                className='my-2 mx-1' 
                onClick={handleSubmit(false, 'financingInformation')}
              > 
                save & exit 
              </ERHButton>
              <ERHButton
                className='my-2 mx-1'
                onClick={handleSubmit(true, 'financingInformation')}
                disabled={!stepComplete}
              > 
                save & continue
              </ERHButton>
          </Col>
      </Row>
    </div>);
};
