import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import JWT from 'jsonwebtoken';
import { DefaultLayout as Layout } from '../Layouts/DefaultLayout';
import { validateEmail, validatePhoneNumber, validateWebsite } from '../helpers';
import {
  state as defaultState, grantCheckboxOptions, grantOptionalInputs,
  defaultOptionalInputs, defualtCheckboxOptions,
} from '../Components/Forms/FormApplication/constants';
import FormApplication from '../Components/Forms/FormApplication';
/* import { Modal } from '../Components/Modal'; */
/* import { ToryBurch as ToryBurchModalContent } from '../Utils/modalsContent'; */
/* import GrantModal from '../Components/GrantModal'; */
import { LoadingPage } from '../Components/Loading';
import { AnimatePresence } from 'framer-motion';
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from '../hooks/useQuery';
import { decodeJWT, setQueryParams } from '../helpers/URL';
import { getLeadData, sendDashboardNotifications, sendTextNotifications } from '../services';

export function ApplicationView() {
  const [data, setData] = useState({
    ...defaultState.data,
    submitted_at: new Date().getTime(),
    landed_at: new Date().toISOString()
  });
  const [validation, setValidations] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [touched, setTouched] = useState({})
  const [hasSubscription, setHasSubscription] = useState(false);
  const [stateGeneral, setStateGeneral] = useState({
    pre_approved_amount: 0,
    showSubmitError: false,
    errormsg: '',
    hideSection: false,
    showAmountError: false,
    showWebsiteError: false,
    showPhoneError: false,
    currentStep: 0,
  });
  const [error, setError] = useState('');
  const addresses = ['home_address', 'home_address_2', 'home_city', 'home_state', 'home_zip_code'];
  const [checkbox, setCheckbox] = useState([]);
  const [optionalInputs, setOptionalInputs] = useState([]);
  const history = useHistory();
  const { leadToken } = useParams();
  const queryParams = useQuery();
  const [completedToryBurchApplication, setCompletedToryBurchApplication] = useState(false)

  const fromCheckout  = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('from_checkout') == 'yes';
  };

  useEffect(() => {
    const leadId = decodeJWT(leadToken);
    if (leadId) {
      (async () => {
        const leadData = await getLeadData(leadId);
        if (leadData) {
          const { currentStep, state } = leadData;
          setStateGeneral({ ...stateGeneral, currentStep });
          setData({ ...data, ...state });
        }
      })();
    }

    const hasQueryParams = !!queryParams.toString();
    hasQueryParams && setQueryParams(queryParams, data, setData);

    (!hasQueryParams && !leadId) && history.push('/');

    setIsLoading(false);
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
    let newTouched = {}
    Object.keys(data).map(key => { newTouched[key] = false })
    setTouched(newTouched)
    if (history.location?.state) {
      const { first_name, last_name, email, toryBurch } = history.location.state
      setData({ ...data, first_name, last_name, email })
      setCompletedToryBurchApplication(toryBurch)
    }
  }, []);

  useEffect(() => {
    if (data.grantSelected) {
      setCheckbox(grantCheckboxOptions);
      setOptionalInputs(grantOptionalInputs);
    } else {
      setCheckbox(defualtCheckboxOptions);
      setOptionalInputs(defaultOptionalInputs);
    }
  }, [data.typeOfFunding, data.grantSelected]);

  useEffect(() => updateValidations(), [data, optionalInputs]);

  const handleInputChange = (event) => {
    const newData = { ...data };
    if (data['same_address_as_home'] && addresses.includes(event.target.name)) {
      newData.company_address = data.home_address;
      newData.company_address_2 = data.home_address_2;
      newData.company_city = data.home_city;
      newData.company_state = data.home_state;
      newData.company_zip_code = data.home_zip_code;
    } else if (event.target.name === 'terms_and_conditions') {
      newData[event.target.name] = !newData[event.target.name];
      setValidations({ ...validation, [event.target.name]: newData[event.target.name] });
    } else if (event.target.type === 'radio') {
      newData[event.target.name] = event.target.value === 'true';
      setValidations({ ...validation, [event.target.name]: true });
    } else if (event.target.type === 'range') {
      newData[event.target.name] = event.target.value;
      setValidations({ ...validation, [event.target.name]: true });
    } else {
      newData[event.target.name] = event.target.value;
    }
    setData(newData);
    handleValidation(event, newData);
  };

  const onBlur = (event) => {
    if (!touched[event.target.name]) {
      setTouched({ ...touched, [event.target.name]: true })
    }
  }

  const handleValidation = (event, newData = data) => {
    const inputValue = (typeof event.target.value == 'string' || event.target.value instanceof String) ? event.target.value.trim() : event.target.value;
    const inputName = event.target.name;
    switch (inputName) {
      case 'phone_number':
        const isValidPhone = !!validatePhoneNumber(inputValue);
        setValidations({ ...validation, [inputName]: isValidPhone });
        break;
      case 'email':
        const isValidEmail = !!validateEmail(inputValue);
        setValidations({ ...validation, [inputName]: isValidEmail });
        break;
      case 'terms_and_conditions':
        setValidations({ ...validation, [inputName]: !!newData[inputName] });
        break;
      case 'company_website':
        const isValidWebsite = !!validateWebsite(inputValue);
        setValidations({ ...validation, [inputName]: isValidWebsite });
        setStateGeneral({ ...stateGeneral, showWebsiteError: !isValidWebsite });
        break;
      case 'loan_amount_requested':
        const isInvalidAmount = inputValue < 10000 || inputValue > 250000;
        setValidations({ ...validation, [inputName]: !isInvalidAmount });
        setStateGeneral({ ...stateGeneral, showAmountError: isInvalidAmount });
        break;
      default:
        if (checkbox.includes(inputName)) {
          setValidations({ ...validation, [inputName]: true });
        } else {
          setValidations({ ...validation, [inputName]: !!inputValue || optionalInputs.includes(inputName) });
        }
        break;
    }
  };

  const handleSubmitValidations = async () => {
    await submitApplicationData();
  };

  const updateValidations = () => {
    const newValidations = { ...validation };
    const newStateGeneral = { ...stateGeneral };
    Object.keys(data).forEach(function (item) {
      const value = (typeof data[item] == 'string' || data[item] instanceof String) ? data[item].trim() : data[item];
      switch (item) {
        case 'phone_number':
          newValidations[item] = !!validatePhoneNumber(value);
          break;
        case 'company_website':
          newValidations[item] = !!validateWebsite(value);
          break;
        case 'email':
          newValidations[item] = !!validateEmail(value);
          break;
        case 'loan_amount_requested':
          if (data.grantSelected) {
            newValidations[item] = true;
          } else {
            const amount = data[item];
            const isInvalidAmount = amount < 10000 || amount > 250000;
            newValidations[item] = !isInvalidAmount;
            newStateGeneral.showAmountError = isInvalidAmount;
          }
          break;
        case 'years_in_business':
          newValidations[item] = value >= 0;
          break;
        case 'credit_score':
          if (data.grantSelected)
            newValidations[item] = true;
          else
            newValidations[item] = value >= 590 && value <= 850;
          break;
        case 'terms_and_conditions':
          newValidations[item] = !!value;
          break;
        default:
          if (checkbox.includes(item)) {
            newValidations[item] = (typeof data[item] === 'boolean');
          } else {
            newValidations[item] = optionalInputs.includes(item) || !!value;
          }
          if (Array.isArray(value) && !optionalInputs.includes(item)) {
            newValidations[item] = !!value.length
          } 
      }
    });
    setValidations(newValidations);
    setStateGeneral(newStateGeneral);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    updateValidations();
    await handleSubmitValidations();
  };

  const submitApplicationData = async () => {
    const isValid = Object.keys(validation).every(item => !!validation[item]);
    if (isValid) {
      setError('');
      setIsLoading(true);
      try {
        const response = await API.post('enrichHer-mvp', 'saveUserApplicationData', { body: data });
        if (response) {
          sendTextNotifications(data.phone_number, data.typeOfFunding);
          await sendDashboardNotifications(data.email, data.typeOfFunding);
          if (hasSubscription || fromCheckout()) {
            let tyPage = 'thank-you/';
            if (data.grantSelected) {
              tyPage += '?t=g';
            } else if (data.toryBurchSelected) {
              tyPage += '?t=tba';
            }
            window.location.replace(process.env.REACT_APP_BASE_WP_URL + tyPage);
          } else {
            let redirectURL = process.env['REACT_APP_THANK_YOU_URL'];
            if (data.grantSelected) {
              redirectURL += '?t=g';
            } else if (data.toryBurchSelected) {
              redirectURL += '?t=tba';
            }
            const payload = {
              redirectOnSuccess: redirectURL,
              email: data.email,
              company: data.company_name,
              stripe_account: 'LE',
              product_type: 'products',
              products: [
                {
                  name: 'Application fee',
                  amount: process.env['REACT_APP_APPLICATION_FEE_PRICE'],
                  transaction_type: 'Application Fee',
                  description: 'Application Fee',
                  stripeProductId: process.env['REACT_APP_APPLICATION_FEE_PRODUCT_ID'],
                  stripePriceId: process.env['REACT_APP_APPLICATION_FEE_PRICE_ID']
                },
              ],
              dashboard: 'Company',
              upsellType: data.typeOfFunding,
              marketing_campaign: {
                utm_campaign: data.utm_campaign,
                utm_source: data.utm_source,
                utm_medium: data.utm_medium
              }
            };
            const token = JWT.sign(
              payload,
              Buffer.from(process.env.REACT_APP_JWT_PRIVATE_KEY, 'base64'),
            );
            const { REACT_APP_URL_CHECKOUT: url } = process.env;
            window.location.replace(url + token);
          }
        }
      } catch (err) {
        setError('Sorry there was an error, please try again');
      }
      setIsLoading(false);
    } else {
      setError('Please complete all required fields');
    }
  };


  const handleSelectInputChange = (inputValue, actionMeta) => {
    let value;
    if (Array.isArray(inputValue)) {
      value = inputValue.map(option => option.value);
    } else if (inputValue) {
      value = inputValue.value;
    }

    if (optionalInputs.includes(actionMeta.name)) {
      setValidations({ ...validation, [actionMeta.name]: true });
    } else if (actionMeta.name === 'years_in_business') {
      setValidations({ ...validation, [actionMeta.name]: value >= 0 });
    } else {
      setValidations({ ...validation, [actionMeta.name]: !!value });
    }

    setData({ ...data, [actionMeta.name]: value });
  };


  return (
    <>
      <AnimatePresence> {isLoading && <LoadingPage isLoading={isLoading} />}  </AnimatePresence>
      {/* <Modal
        content={ToryBurchModalContent}
        show={completedToryBurchApplication}
      /> */}
      {/* <GrantModal 
        title='EnrichHER $5K Grant'
        body='Application accepted until February 18th, and one winner to be announced in March.'
        btnText='OK'
        fundingType={data.typeOfFunding}
      /> */}
      <Layout className='view-application' containerFluid={false}>
        <div className='p-1'>
          <h1 className='mb-2 mt-4'> Apply for funding </h1>
          <FormApplication
            step={stateGeneral.currentStep}
            values={data}
            setValues={setData}
            setIsLoading={setIsLoading}
            error={error}
            setError={setError}
            validations={validation}
            setValidations={setValidations}
            hasSubscription={hasSubscription}
            setHasSubscription={setHasSubscription}
            onSubmit={handleFormSubmit}
            onBlur={onBlur}
            touched={touched}
            onChange={handleInputChange}
            handleSelectInputChange={handleSelectInputChange}
            completedToryBurchApplication={completedToryBurchApplication}
          />
        </div>
      </Layout>
    </>
  );
}


