import React from 'react';

export function ERHCheckbox({
                              classInput = '',
                              classLabel = 'null',
                              type,
                              classContainer = '',
                              label,
                              labelInCheck,
                              error = '',
                              styled,
                              ...rest
                            }) {

  return (
    <div className={classContainer}>
      {label && <label className={`label-input ${classLabel}`} htmlFor={rest.id}> {labelInCheck} </label>}

      <input className='inp-cbx' type='checkbox'
             {...rest}
             style={{ display: 'none' }} />

      <label className={styled ? "cbx-2" : "cbx"} htmlFor={rest.id}><span>
                            <svg width='12px' height='10px' viewBox='0 0 12 10'>
                              <polyline points='1.5 6 4.5 9 10.5 1'></polyline>
                            </svg></span><span>{label}</span></label>
      {error && <p className={`error-input`}>{error}</p>}
    </div>);
}
