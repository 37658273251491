import React from 'react';
import IconCheck from '../../../Assets/icons/icon-check.svg';
import { ERHButton } from '../../Button';

export const CompleteFormView = () => {

  return (
    <div className='complete-form-view'>
      <div className='container-complete'>
        <div className='icon'>
          <img src={IconCheck} alt={'check-icon'} />
        </div>
        <h1 className='title my-4'>
          Your EnrichHER Capital Application
        </h1>
        <p className='paragraph my-2'>
          Thank you for applying to recieve funding as a participant in our All Rise Factory Cohort.
        </p>
        <p className='paragraph my-2'>
          Based on the information you provided, funding and loan options will be made available for you to apply.
        </p>
        <p className='paragraph my-2'>
          If we do not recieve the total ampunt due on any monthly payment, we will charge a late payment fee for that
          month. A return payment fee will be charged to any payment that is returned unpaid.
        </p>
        <ERHButton className='px-4 py-2 my-4' href={process.env.REACT_APP_BASE_WP_URL + "dashboard/"}>
          Go Back to Dashboard to see Options
        </ERHButton>
      </div>
    </div>
  );
};
