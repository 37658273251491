import React from 'react';
import { Header } from '../Components/Header';
import Footer from "./Footer";
import { Container } from 'reactstrap';

export const DefaultLayout = ({ children, className, containerFluid = true }) => {
  const [isOpen, setOpen] = React.useState(false);
  const toggle = () => setOpen(!isOpen);
  return (
    <div className={className}>
      <Header isOpen={isOpen} toggle={toggle} />
      <div className='wrapper-content'>
        <Container fluid={containerFluid}>
          {children}
        </Container>
      </div>
      <Footer />
    </div>);
};

