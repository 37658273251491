import React from 'react';
import ReactDom from 'react-dom';


export const ModalLayout = ({ children, isOpen }) => {
  if (!isOpen) return null;

  const modal = (
  <>
    <div className="overlay" />
    {children}
  </>
  );

  return ReactDom.createPortal(modal, document.getElementById('modal'));
}
