import React from 'react';
import { DefaultLayout as Layout } from '../Layouts/DefaultLayout';
import { CompleteFormView } from '../Components/Forms/FormApplication/CompleteForm';

export const CompleteStepsView = () => {
  return (
    <Layout className='view-application' containerFluid={false}>
      <CompleteFormView />
    </Layout>);
};
