import React from 'react';
import LogoDesktop from '../Assets/logos/logo-deskop.svg';
import { Collapse, Container, Nav, Navbar, NavItem } from 'reactstrap';

export const Header = ({ isOpen, toggle }) => {
  return (
    <Navbar expand='md'>
      <Container className='navbar-inner'>
        <div className='toggle' onClick={toggle}>
          <div />
          <div />
          <div />
        </div>
        <div className='link-logo'>
          <a href={`${process.env.REACT_APP_BASE_WP_URL}`}>
            <img
              className='logo'
              src={LogoDesktop}
              alt='EnrichHer Logo'
            />
          </a>
        </div>

        <Collapse className='menu' isOpen={isOpen} navbar >
          <Nav navbar horizontal={'right'} >
            <NavItem>
              <a href={process.env.REACT_APP_BASE_WP_URL + 'dashboard'}>
                Dashboard
              </a>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
    ;
};
