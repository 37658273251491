import React from 'react';


export const ERHRadioBtn = ({
                              label,
                              classContainer = '',
                              negative = false,
                              classLabel = '',
                              className = '',
                              type,
                              ...rest
                            }) => {

  return (
    <label htmlFor={rest.id} className={`container-radio-btn ${classContainer} ${negative? 'negative': ''}`}>
      <div>
        <input className={`ERH-radio-btn ${className}`} type='radio' {...rest} />
        <div className='borders'/>
      </div>
      {label && <label className={`label-radio-btn ${classLabel}`} htmlFor={rest.id}> {label} </label>}
    </label>
  );
};
