import React from 'react';
import { Col, Row } from 'reactstrap';
import { ERHSelectTooltip } from '../../Select.jsx';
import { ERHButton } from '../../Button';

import {
  optionsHowDidYouHearAboutUs,
  optionsMarketingBudget,
  optionsSocialMediaChanels as optionsSocialMediaChannels,
  optionsSocialMediaFollowing,
} from '../../../helpers';
import { Error } from './Error';

export const FormMarketing = ({
  backStep,
  nextStep,
  currentStep,
  values,
  setIsLoading,
  error,
  setError,
  touched,
  handleSelectInputChange,
  validations,
  handleSubmit,
  ...rest
}) => {
  const {
    social_media_channels,
    total_social_media_following,
    monthly_marketing_budget,
    how_did_you_hear_about_us,
  } = validations;
  const isCompleteStep = () => {
    if (!rest.disabled) {
      if (values.grantSelected) {
        return how_did_you_hear_about_us
      }
      return (
        social_media_channels
        && total_social_media_following
        && monthly_marketing_budget
        && how_did_you_hear_about_us
      )
    }
    return false;
  };
  const stepComplete = React.useMemo(isCompleteStep, [
    social_media_channels,
    total_social_media_following,
    monthly_marketing_budget,
    how_did_you_hear_about_us,
    rest.disabled
  ]);


  return (
    <div className={'fragment-form-application'}>
      <h3 className='title-form my-3'> Marketing information </h3>
      <div className='form'>
        <Row>
          {!values.grantSelected &&
            <>
              <Col xs={12}>
                <ERHSelectTooltip
                  tooltip='Please select the digital channels you use to attract customers to your business.' isMulti
                  name='social_media_channels' options={optionsSocialMediaChannels}
                  onChange={handleSelectInputChange} id='social_media_channels'
                  label='Which digital marketing channels do you use? (Select all that apply)'
                  value={values.social_media_channels}
                  classContainer={'my-2'} />
              </Col>
              <Col xs={12}>
                <ERHSelectTooltip
                  tooltip='This number is used to estimate the total approximate reach of your earned social media digital marketing activities.'
                  type='text' id='total_social_media_following'
                  name='total_social_media_following'
                  options={optionsSocialMediaFollowing}
                  label='What is the total social media following of both your company and personal profile (if used for business) for the social media platforms selected in question 1?'
                  value={values.total_social_media_following}
                  classContainer={'my-2'} onChange={handleSelectInputChange} />
              </Col>
              <Col xs={12}>
                <ERHSelectTooltip
                  tooltip={'Your monthly marketing budget includes all in-house resources for campaign management, graphic design, social media, public relations, and paid advertisement.\n' +
                    '                              Please include digital and non-digital values.'}
                  id={'monthly_marketing_budget'} name='monthly_marketing_budget' options={optionsMarketingBudget}
                  label='What is your monthly marketing budget?'
                  classContainer={'my-2'} 
                  value={values.monthly_marketing_budget}
                  onChange={handleSelectInputChange} />
              </Col>
            </>
          }
          <Col xs={12}>
            <ERHSelectTooltip
              tooltip={'How did you learn about the EnrichHER Capital options? Please select all that apply'}
              id='how_did_you_hear_about_us' name='how_did_you_hear_about_us' isMulti
              options={optionsHowDidYouHearAboutUs}
              label='How did you hear about us?*' classContainer={'my-2'}
              onChange={handleSelectInputChange} value={values.how_did_you_hear_about_us}/>
          </Col>
        </Row>
      </div>
      {error && <Error error={error} />}
      <Row className="my-4">
        <Col xs="12" md="4">
          <ERHButton className='my-2' onClick={backStep} outline> Back </ERHButton>
        </Col>
        <Col xs="12" md="8" className="d-flex justify-content-end flex-wrap">
          <ERHButton
            className='my-2 mx-1'
            onClick={handleSubmit(false, 'marketingInformation')}
          >
            save & exit
          </ERHButton>
          <ERHButton
            className='my-2 mx-1'
            onClick={handleSubmit(true, 'marketingInformation')}
            disabled={!stepComplete}
          >
            save & continue
          </ERHButton>
        </Col>
      </Row>
    </div>
  );
};
