import { API } from 'aws-amplify';
import { fundingOptions } from './Components/Forms/FormApplication/constants';

export const getLeadData = async (lead_id) => {
  try {
    const { status ,data } = await API.post('enrichHer-mvp', 'getUserLead', { response: true, body: { lead_id } });
    if (status === 200) {
      const { current_step: currentStep, step0, step1, step2, step3 } = data;
      return {
        currentStep,
        state: { ...step0, ...step1, ...step2, ...step3 }
      }
    } 
    return null;
  } catch {
    return null;
  }
}

export const handleApplicationNotification = async (type, notification, contact) => {
  const apiName = 'wordpress';
  const path = '/wp-json/erh_notifications/v1/handle_trigger'; 
  const config = { 
    body: {
      type,
      contact_id: contact,
      notification_id: notification, 
    }
  };
  await API.post(apiName, path, config);
}

export const getAccountVerification = async (email) => {
  try {
    const apiName = 'wordpress';
    const path = '/wp-json/zoho/v1/user_account_verification'; 
    const config = { 
      queryStringParameters: { 
        email,
        time: +Date.now(),
      } 
    };
    const response = await API.get(apiName, path, config);
    return !!response?.id;
  } catch {
    return false;
  }
}

export const sendDashboardNotifications = async (email, typeOfFunding) => {
  const appliedForGrant = [fundingOptions.grant, fundingOptions.allFunding].includes(typeOfFunding);
  try {
    const [hasAccountVerification, ...rest] = await Promise.all([
      getAccountVerification(email),
      handleApplicationNotification('REMOVE', 'RESUME_YOUR_APPLICATION', email),
      appliedForGrant && handleApplicationNotification('NOTIFICATE', 'GRANT_APPLICATION_NEXT_STEPS', email),
    ]);
    !hasAccountVerification && await handleApplicationNotification('NOTIFICATE', 'ADD_BANK_DETAILS', email);
  } catch (error) {
    return;
  }
}

export const sendTextNotifications = async (phoneNumber, typeOfFunding) => {
  const appliedForGrant = [fundingOptions.grant, fundingOptions.allFunding].includes(typeOfFunding);
  try {
    if (appliedForGrant) {
      const payload = {
        type: 'GRANT_APPLICATION_NEXT_STEPS',
        phoneNumber,
        message: `Now that you've applied to the grant, login to your dashboard periodically to check your status. We will also send a notification to check the dashboard.`,
      }
      handleGrantTextNotification(payload);
    }
  } catch (error) {
    return;
  }
}

export const handleGrantTextNotification = async (payload) => {
  const body = updateGrantTextNotificationPayload(payload);
  const apiName = 'enrichHer-mvp';
  const path = 'grantNotifications'; 
  const config = { body };
  await API.post(apiName, path, config);
}

const updateGrantTextNotificationPayload = (payload) => {
  if (payload?.type === 'RESUME_YOUR_APPLICATION') {
    payload.message = `Please click here to resume your application ${window.location.href}`;
    payload.delay = 24;
    return payload;
  }
  return payload;
} 