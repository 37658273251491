import React from 'react';
import 'react-rangeslider/lib/index.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './Styles/css/bootstrap.min.css';
import './Styles/index.scss';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ApplicationView } from './Views/Application';
import { CompleteStepsView } from './Views/CompleteStep';
/* import { ToryBurch } from './Views/ToryBurch';
import StoreProvider from './ToryBurch/stores/FormStore/StoreProvider'
import DataProvider from './ToryBurch/stores/DataStore/DataProvider';  */

function App() {
  return (
    <div className='App'>
      <Router>
        <Switch>
          <Route exact path='/complete' component={CompleteStepsView} />
          <Route path='/:leadToken?' component={ApplicationView} /> 
          {/* <StoreProvider>
            <DataProvider>
              <Route exact path='/toryburch' component={ToryBurch} />
            </DataProvider>
          </StoreProvider> */}
          <Redirect to='/' />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
