import React from 'react'
import TopFooterColumn from './TopFooterColumn'
import Logo from '../../Assets/logos/enrichher-logo-white.svg'
import B_Certified from '../../Assets/b-certified.png' 

const enrichHerOptions = [
    {
        title: 'Blog',
        url: `${process.env.REACT_APP_BASE_WP_URL}blog`
    },
    {
        title: 'Press',
        url: `${process.env.REACT_APP_BASE_WP_URL}press`
    },
    {
        title: 'About Us',
        url: `${process.env.REACT_APP_BASE_WP_URL}learn`
    },
    <img src={B_Certified} id='b-certified' alt='B-Certified logo'/>
]

const fundersOptions = [
    {
        title: 'Foundations, Impact Funds & Corporate Giving',
        url: `${process.env.REACT_APP_BASE_WP_URL}funders`
    },
    {
        title: 'Individuals and Small Groups',
        url: `${process.env.REACT_APP_BASE_WP_URL}individual-funders`
    },
    {
        title: 'Learn About Funder Matching Program',
        url: `${process.env.REACT_APP_BASE_WP_URL}all-rise-factory-cohort-1`
    },
    {
        title: 'Donate now',
        url: `${process.env.REACT_APP_BASE_WP_URL}donate-now`
    },
    {
        title: 'How EnrichHER Makes It Easy',
        url: `${process.env.REACT_APP_BASE_WP_URL}how-enrichher-makes-investing-easy`
    },
    {
        title: 'Risk Warning',
        url: `${process.env.REACT_APP_BASE_WP_URL}risk`
    },
    {
        title: 'Sign Up for Free Account/Donate',
        url: `${process.env.REACT_APP_BASE_WP_URL}register`
    },
    {
        title: 'Subscribe to Weekly Funding Opportunities',
        url: `https://go.enrichher.com/meet-enrichher-pg`
    },
    {
        title: 'Attend Platform Demo',
        url: `https://calendly.com/enrichher-customer-success/enrichher-private-beta-demo`
    }
]

const companiesOptions = [
    {
        title: 'Overview',
        url: `${process.env.REACT_APP_BASE_WP_URL}companies`
    },
    {
        title: 'Apply for Loan',
        url: `https://app.enrichher.com/`
    },
    {
        title: 'Apply for Grant',
        url: `https://go.enrichher.com/5k-grant-lp-b`
    },
    {
        title: 'Enroll for Accelerator',
        url: `${process.env.REACT_APP_BASE_WP_URL}accelerator`
    },
    {
        title: 'Subscribe to Weekly Funding Updates',
        url: `https://go.enrichher.com/meet-enrichher-pg`
    },
    {
        title: 'Login to EnrichHER Society',
        url: `https://society.ienrichher.com/sign_in`
    },
    {
        title: 'Review Loan Terms',
        url: `${process.env.REACT_APP_BASE_WP_URL}loan-terms`
    }
]

const connectWithUsOptions = [
    {
        icon: 'qode_icon_font_elegant icon_mail_alt qode_iwt_icon_element',
        text: `hello@enrichher.com`,
        url: `mailto:hello@${process.env.REACT_APP_WP_URL}`
    },
    {
        icon: 'qode_icon_font_elegant icon_pin_alt qode_iwt_icon_element',
        text: '1100 Peachtree St NE, Suite 250, Atlanta, GA 30309',
        url: 'https://www.google.com/maps/place/1100+Peachtree+St+NE,+Atlanta,+GA+30309/@33.7850015,-84.3860443,17z/data=!3m1!4b1!4m5!3m4!1s0x88f5044372f9b68d:0xbd7941b125a1ffb2!8m2!3d33.7850015!4d-84.3838556'
    }
]

const columns = [
    [<img src={Logo} id='footer-logo' />, enrichHerOptions],
    ['FUNDERS', fundersOptions],
    ['COMPANIES', companiesOptions],
    ['CONNECT WITH US', connectWithUsOptions]
]

const TopFooter = () => {
    return (
        <div className='footer_top_holder'>
            <div className='footer_top'>
                <div className='container'>
                    <div className='d-flex flex-grow-1 flex-wrap columns-box'>
                        {columns.map((column, index) =>
                            <TopFooterColumn key={index} column={column} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopFooter
