import React from 'react'
import BottomFooter from '../Components/Footer/BottomFooter'
import TopFooter from '../Components/Footer/TopFooter'

const Footer = () => {
    return (
        <footer className='page-footer font-small'>
            <TopFooter />
            <BottomFooter />
        </footer>
    )
}

export default Footer
