import React, { useState, useEffect } from 'react'
import { ERHButton } from './Button';

export const Modal = ({ content, show, onClick }) => {
    const { title, body, btnText } = content
    const [showModal, setShowModal] = useState(show ?? false)

    useEffect(() => {
        setShowModal(show)
    }, [show])

    const handleOnClick = () => {
        onClick()
        setShowModal(false)
    }

    return showModal && (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h4>{title}</h4>
                </div>
                <div className="modal-body">
                    {body}
                </div>
                <div className="modal-footer">
                    <ERHButton onClick={handleOnClick}>
                        {btnText}
                    </ERHButton>
                </div>
            </div>
        </div>
    )
}

