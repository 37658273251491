import React from 'react';
import { API } from 'aws-amplify';
import { ERHInput } from '../../Input';
import { Col, Row, Tooltip } from 'reactstrap';
import { ERHSelect, ERHSelectTooltip } from '../../Select.jsx';
import { ERHButton } from '../../Button';
import {
  optionsDisabilitySelect,
  optionsGenderSelect,
  optionsLGTBSelect,
  optionsMilitaryAffiliationSelect,
  optionsRaceSelect,
} from '../../../helpers';
import { Error } from './Error';
import InterrogateIcon from '../../../Assets/icons/interrogate.svg';

export const FormPersonalInformation = ({
  backStep,
  nextStep,
  values,
  validations,
  currentStep,
  setIsLoading,
  handleSelectInputChange,
  currentValueSelect,
  error,
  touched,
  setError,
  handleSubmit,
  ...rest
}) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const {
    home_address,
    home_address_2,
    home_city,
    home_state,
    gender,
    home_zip_code,
    race,
    phone_number,
    military_affiliation,
    do_you_identify_as_lgbtq,
    individual_with_disabilities,
  } = validations;

  const isCompleteStep = () => {
    if (!rest.disabled) {
      const isEveryTrue = home_address && home_address_2 && home_city &&
        home_state && home_zip_code && race &&
        military_affiliation && do_you_identify_as_lgbtq && individual_with_disabilities && phone_number && gender;
      return !!isEveryTrue;
    }
    return false;
  };

  

  const stepComplete = React.useMemo(isCompleteStep, [
    home_address,
    home_address_2,
    home_city,
    home_state,
    gender,
    home_zip_code,
    race,
    military_affiliation,
    do_you_identify_as_lgbtq,
    individual_with_disabilities,
    phone_number,
    rest.disabled
  ]);


  return (
    <div className={'fragment-form-application'}>
      <h3 className='title-form my-3'> Personal Information </h3>
      <div className='form'>
        <Row>
          <Col xs={12}>
            <ERHInput value={values.phone_number} name='phone_number' type='text' id='phone_number'
              label='Mobile Phone Number*' placeholder='Enter mobile phone number' classContainer={'my-2'}
              error={touched.phone_number && !validations.phone_number && 'Please enter a valid mobile phone number.'}
              {...rest} />
          </Col>
          <Col xs={12}>
            <ERHInput value={values.home_address} name='home_address' type='text' id='home_address'
              label='Street address 1*' placeholder='Enter street address' classContainer={'my-2'} {...rest} />
          </Col>
          <Col xs={12}>
            <ERHInput value={values.home_address_2} type='text' id='home_address_2' name='home_address_2'
              label='Street address 2' placeholder='Enter street address' classContainer={'my-2'} {...rest} />
          </Col>
          <Col xs={12} sm={6} md={4}>
            <ERHInput name='home_city' value={values.home_city} type='text' id='home_city' label='City*'
              placeholder='City' classContainer={'my-2'} {...rest} />
          </Col>
          <Col xs={12} sm={6} md={4}>
            <ERHInput name={'home_state'} value={values.home_state} type='text' id='home_state' label='State*'
              placeholder='State' classContainer={'my-2'} {...rest} />
          </Col>
          <Col xs={12} sm={6} md={4}>
            <ERHInput type='number' name={'home_zip_code'} value={values.home_zip_code} id='last-name' label='Zip Code*'
              placeholder='Zip Code'
              classContainer={'my-2'} {...rest} />
          </Col>
        </Row>
        <h3 className='title-form my-3 d-flex align-items-center'> Diversity deep dive
          <div>
            <Tooltip
              id='tooltip-diversity' placement='top' Open={tooltipOpen} target='tooltip-diversity' toggle={toggle}>
              EnrichHER is committed to provide funding opportunities to women-led businesses
            </Tooltip>
            <img className='mx-2' src={InterrogateIcon} alt='' id='tooltip-diversity' />
          </div>
        </h3>
        <Row>
          <Col xs={12}>
            <ERHSelectTooltip
              tooltip="EnrichHER's goal is to provide capital to gender diverse teams. Please select all that apply."
              isMulti options={optionsGenderSelect} classContainer={'my-2'}
              label={'Please share the gender diversity of your company.*'} id={'gender'}
              name='gender' value={values.gender} onChange={handleSelectInputChange}
            />
          </Col>
          <Col xs={12}>
            <ERHSelect isMulti name={'race'} classContainer={'my-2'} label={'What race do you identify as?*'}
              id={'race'} options={optionsRaceSelect} value={values.race} onChange={handleSelectInputChange} />
          </Col>
          <Col xs={12}>
            <ERHSelect name='military_affiliation' options={optionsMilitaryAffiliationSelect} classContainer={'my-2'}
              label={'Do you have any military affiliation?*'} id={'military_affiliation'} 
              value={values.military_affiliation} onChange={handleSelectInputChange} />
          </Col>
          <Col xs={12}>
            <ERHSelect name='do_you_identify_as_lgbtq' options={optionsLGTBSelect} classContainer={'my-2'}
              label={'Do you identify as LGBTQ+?*'} id={'do_you_identify_as_lgbtq'}
              value={values.do_you_identify_as_lgbtq} onChange={handleSelectInputChange} />
          </Col>
          <Col xs={12}>
            <ERHSelect 
              classContainer={'my-2'}
              label={'Do you consider yourself to be an individual with a disability or disabilities?*'}
              id={'individual_with_disabilities'}
              options={optionsDisabilitySelect}
              name='individual_with_disabilities'
              maxMenuHeight={250}
              value={values.individual_with_disabilities}
              onChange={handleSelectInputChange} />

          </Col>
        </Row>
        {error && <Error error={error} />}
        <Row className="my-4">
          <Col xs="12" md="4">
            <ERHButton className='my-2' onClick={backStep} outline> Back </ERHButton>
          </Col>
          <Col xs="12" md="8" className="d-flex justify-content-end flex-wrap">
            <ERHButton
              className='my-2 mx-1'
              onClick={handleSubmit(false, 'personalInformation')}
            >
              save & exit
            </ERHButton>
            <ERHButton
              className='my-2 mx-1'
              onClick={handleSubmit(true, 'personalInformation')}
              disabled={!stepComplete}
            >
              save & continue
            </ERHButton>
          </Col>
        </Row>
      </div>
    </div>);
};
